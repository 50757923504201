<template>
    <div class="listGrid">
        
        <div class="title">
            <h3>My trailers ({{ trailers.length }})</h3>
            <button class="default small" @click="editItem({})">Add</button>
        </div>

        <div class="list" v-if="trailers.length > 0">
            <div class="item header">
                <div class="col">Trailer #</div>
                <div class="col">License Plate</div>
                <div class="col">Truck #</div>
                <div class="col">Driver</div>
                <div class="col"></div>
            </div>
            <TrailerItem class="item" @remove="removeItem(trailer._id)" @edit="editItem(trailer)" :key="trailer._id" v-for="trailer of trailers" :trailer="trailer"/>
        </div>
        <div class="noTrucksMessage" v-else>
            <div class="icon"><i class="fa-solid fa-truck-fast"></i></div>
            <div class="message">You haven't added any trailers yet.</div>
        </div>

        <ModalSimple v-model="item.modal" :title="item.body._id ? 'Edit Trailer' : 'Add Trailer'" @confirm="handleSave" confirm="Save trailer" @close="closeAddModal">
            <SaveTrailer :item="item.body" v-if="item.modal" :errors="item.errors"/>
        </ModalSimple>

    </div>
</template>

<script>
import TrailerItem from '../TrailerItem.vue'
import SaveTrailer from './Save.vue'


    export default {
        components: {
            TrailerItem,
            SaveTrailer
        },
        props: ['trailers'],
        data() {
            return {
                item: {
                    modal: false,
                    body: {},
                    errors: {}
                },
            }
        },
        methods: {
            removeItem(id) {
                let confirmDelete = confirm("Do you really want to delete this truck?");
                if(!confirmDelete) return;

                this.ajax('deleteTruck', {
                    url: `/myTrailers/${id}`,
                    method: 'DELETE',
                }, (err, body) => {
                    if(err) {
                        alert(body.message || 'Something went wrong. Please, try again!');
                        return;
                    }
                    this.$emit('refresh');
                });
            },
            closeAddModal() {
                this.item.body = {};
                this.item.errors = {};
            },
            editItem(item) {
                this.item.body = JSON.parse(JSON.stringify(item));
                this.item.modal = true;
            },
            handleSave() {
                this.item.errors = {};
                this.ajax('saveItemDetails', {
                    url: '/myTrailers',
                    method: 'POST',
                    data: this.item.body
                }, (err, body) => {
                    if(err) {
                        if(body.errors) this.item.errors = body.errors;
                        else alert(body.message || 'Something went wrong. Please, try again!');
                        return;
                    }
                    this.item.modal = false;
                    this.item.body = {};
                    this.$emit('refresh');
                });
            },
        },
    }
</script>

<style lang="scss" scoped>
.listGrid {
    display: grid;
    row-gap: 20px;
}


.title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.list {
    overflow-x: auto;
    border-radius: $borderRadius;
    .item {
        min-width: 1060px;
    }
    .header {
        display: grid;
        grid-template-columns: 250px 200px minmax(0, 1fr) minmax(0, 1fr) 55px;
        background: $bgSecondary;
        padding: 20px;
        align-items: center;
        border-bottom: 1px solid $borderColor;
        column-gap: 10px;
        font-size: 12px;
        .col {
            font-weight: bold;
            text-transform: uppercase;
        }
    }
}

.noTrucksMessage {
    padding: 100px 30px;
    display: grid;
    row-gap: 30px;
    align-items: center;
    justify-items: center;
    background: $bgSecondary;
    border-radius: $borderRadius;
    .icon {
        font-size: 50px;
    }
    .message {
        font-size: 14px;
        opacity: 0.8;
    }
}

.newItemContent {
    display: grid;
    row-gap: 20px;
}
</style>