<template>
    <div class="newItemContent">
        

        <div class="input">
            <div class="_header">
                <label>DOT Inspection Expiration Date</label>
                <span class="_error">{{ errors.dotInspectionExpiration }}</span>
            </div>
            <div class="dateHolder" :class="{ 'active' : show.has('dotInspectionExpiration') }">
                <div class="date"><a href="" @click.prevent="switchShow('dotInspectionExpiration')">{{ dateToString(item.dotInspectionExpiration) }}</a></div>
                <div class="clear"><a href="" @click.prevent="item.dotInspectionExpiration = null">Clear</a></div>
                <DatePickerSimple class="calendar" v-click-outside="() => { hideShow('dotInspectionExpiration') }" v-model="item.dotInspectionExpiration" @select="hideShow('dotInspectionExpiration')" v-if="show.has('dotInspectionExpiration')"/>
            </div>
        </div>

        <div class="input">
            <div class="_header">
                <label>Plates Expiration Date</label>
                <span class="_error">{{ errors.platesExpiration }}</span>
            </div>
            <div class="dateHolder" :class="{ 'active' : show.has('platesExpiration') }">
                <div class="date"><a href="" @click.prevent="switchShow('platesExpiration')">{{ dateToString(item.platesExpiration) }}</a></div>
                <div class="clear"><a href="" @click.prevent="item.platesExpiration = null">Clear</a></div>
                <DatePickerSimple class="calendar" v-click-outside="() => { hideShow('platesExpiration') }" v-model="item.platesExpiration" @select="hideShow('platesExpiration')" v-if="show.has('platesExpiration')"/>
            </div>
        </div>


        <div class="input">
            <div class="_header">
                <label>Insurance Expiration Date</label>
                <span class="_error">{{ errors.insuranceExpiration }}</span>
            </div>
            <div class="dateHolder" :class="{ 'active' : show.has('insuranceExpiration') }">
                <div class="date"><a href="" @click.prevent="switchShow('insuranceExpiration')">{{ dateToString(item.insuranceExpiration) }}</a></div>
                <div class="clear"><a href="" @click.prevent="item.insuranceExpiration = null">Clear</a></div>
                <DatePickerSimple class="calendar" v-click-outside="() => { hideShow('insuranceExpiration') }" v-model="item.insuranceExpiration" @select="hideShow('insuranceExpiration')" v-if="show.has('insuranceExpiration')"/>
            </div>
        </div>

    </div>
</template>

<script>
import DatePickerSimple from '../DatePickerSimple.vue'
import moment from 'moment'

    export default {
        components: {
            DatePickerSimple
        },
        props: ['item', 'errors'],
        data() {
            return {
                show: new Set()
            }
        },
        methods: {
            dateToString(date) {
                if(!date) return 'Not selected';
                let mDate = moment.utc(date);
                if(!mDate.isValid()) return 'Not selected';
                return mDate.format("MM/DD/YYYY");
            },
            switchShow(item) {
                if(this.show.has(item)) {
                    this.show.delete(item);
                    return;
                }
                this.show.add(item);
            },
            hideShow(item) {
                this.show.delete(item);
            }
        },
        mounted() {
            
        }
    }
</script>

<style lang="scss" scoped>
.newItemContent {
    display: grid;
    row-gap: 10px;
}

.dateHolder {
    position: relative;
    padding: 0 10px;
    height: 40px;
    border: 1px solid $borderColor;
    align-items: center;
    display: flex;
    justify-content: space-between;
    background: $bgSecondary;
    border-radius: $borderRadius;
    font-size: 13px;
    &.active  {
        z-index: 2;
    }
    .calendar {
        position: absolute;
        top: 100%;
        left: 0;
        transform: translate(0, 20px);
    }
}

</style>