<template>
    <div class="contentGrid">

        <div class="headerHolder">
            <div class="tabs">
                <div class="tab" :class="{ 'active' : selectedTab == key }" @click="selectedTab = key" :key="key" v-for="(tab, key) of tabsFilter">
                    <div class="name">{{ tab.name }}</div>
                    <span class="count" v-if="tab.count > 0">{{ tab.count }}</span>
                </div>
            </div>
        </div>

        <div class="componentHolder">
            <component :is="tabs[selectedTab].component" :item="item" :errors="errors" :documentsOptions="documentsOptions"></component>
        </div>

    </div>
</template>

<script>
import { shallowRef } from '@vue/reactivity'
import General from './General.vue'
import TruckInfo from './TruckInfo.vue'
import Safety from './Safety.vue'
import Docs from '../Documents/Docs.vue'


    export default {
        components: {
            General,
            TruckInfo,
            Safety,
            Docs
        },
        props: ['item', 'errors'],
        data() {
            return {
                tabs: [
                    { name: 'General', component: shallowRef(General) },
                    { name: 'Truck Info', component: shallowRef(TruckInfo) },
                    { name: 'Safety', component: shallowRef(Safety) },
                    { name: 'Documents', component: 'Docs', idRequired: true },
                ],
                documentsOptions: {
                    getUrl: this.item._id ? `/myTrucks/documents/${this.item._id}` : null,
                    uploadUrl: this.item._id ? `/myTrucks/documents/${this.item._id}/upload` : null,
                    renameUrl: `/myTrucks/documents/rename`,
                    removeUrl: `/myTrucks/documents/remove`,
                },
                selectedTab: 0
            }
        },
        computed: {
            tabsFilter() {
                return this.tabs.filter(item => {
                    if(item.idRequired) {
                        if(this.item._id) return true;
                        else return false;
                    }
                    return true;
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
.contentGrid {
    display: grid;
    row-gap: 20px;
}

.headerHolder {
    border-bottom: 1px solid $borderColor;
    .tabs {
        display: flex;
        flex-wrap: wrap;
        .tab {
            padding: 0 20px;
            display: flex;
            height: 50px;
            align-items: center;
            cursor: pointer;
            // border-radius: 5px 5px 0 0;
            transition: ease 0.3s;
            user-select: none;
            .count {
                padding: 5px 7px;
                background: $buttonColor2;
                border-radius: 5px;
                font-size: 12px;
                font-weight: bold;
                transition: ease 0.3s;
                margin-left: 15px;
            }
            .name {
                font-size: 14px;
                transition: ease 0.3s;
                font-weight: bold;
            }
            &.active {
                background: $buttonColor2;
                color: $buttonColor;
                .count {
                    color: $buttonText;
                    background: $buttonColor;
                }
            }
        }
    }
}
</style>