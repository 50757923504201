export default {
    data() {
        return {
            _filePicker: null,
            _filePickerCallback: null
        }
    },
    methods: {
        createFilePicker(fileTypes = null, multiple = false, callback = null) {
            if(callback) this._filePickerCallback = callback;
            this._filePicker = null;
            this._filePicker = document.createElement('input');
            this._filePicker.type = "file";
            this._filePicker.setAttribute("hidden", true);

            if(multiple) this._filePicker.setAttribute("multiple", true);

            if(fileTypes) this._filePicker.setAttribute("accept", fileTypes);
            this._filePicker.addEventListener('change', (ev) => {
                let files = ev.target.files;
                if(files.length > 0) {
                    this._filePickerCallback(files);
                    this._filePicker = null;
                    this._filePickerCallback = null;
                }
            });
            document.body.appendChild(this._filePicker);
            this._filePicker.click();
            this._filePicker.remove();
        }
    }
}