<template>
    <div class="trailer">
        <div class="name">{{ trailer.name }}</div>
        <div>{{ trailer.licensePlate }}</div>
        <div>{{ trailer.truck ? trailer.truck.name : 'Not assigned' }}</div>
        <div>{{ driver }}</div>
        <div class="actionBtns">
            <button @click="$emit('edit')"><i class="fa-solid fa-pencil"></i></button>
            <button @click="$emit('remove')" class="remove"><i class="fa-solid fa-trash-can"></i></button>
        </div>
    </div>
</template>

<script>
import moment from 'moment'

    export default {
        props: ['trailer'],
        computed: {
            driver() {
                if(!this.trailer.truck) return '';
                if(!this.trailer.truck.driver) return '';
                let name = this.trailer.truck.driver.name;
                if(this.trailer.truck.driver.lastname) name += ' ' + this.trailer.truck.driver.lastname;
                return name;
            }
        }
    }
</script>

<style lang="scss" scoped>
.trailer {
    position: relative;
    background: $bgSecondary;
    padding: 20px;
    align-items: center;
    border-bottom: 1px solid $borderColor;
    display: grid;
    column-gap: 10px;
    grid-template-columns: 250px 200px minmax(0, 1fr) minmax(0, 1fr) 55px;
    &:hover {
        z-index: 1;
        box-shadow: $boxShadow;
    }
    .name {
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
    }
    .details {
        color: $subtle;
    }
}

.actionBtns {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 7px;
    button {
        border: 0;
        border-bottom: 3px solid $borderColor;
        width: 24px;
        height: 24px;
        border-radius: 5px;
        font-size: 10px;
        color: $text;
        background: $buttonColor2;
        &.remove {
            color: #C0392B;
        }
    }
}

</style>