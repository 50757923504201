<template>
    <div class="newItemContent">
        
        <Input name="Make" placeholder="Make" :error="errors.make" v-model="item.make"/>
        <Input name="Model" placeholder="Model" :error="errors.model" v-model="item.model"/>
        <Input name="Color" placeholder="Color" :error="errors.color" v-model="item.color"/>
        <Input name="Year" placeholder="Year" :error="errors.year" v-model="item.year"/>

        <Input name="License Plate" placeholder="License Plate" :error="errors.licensePlate" v-model="item.licensePlate"/>
        <Input name="VIN" placeholder="VIN" :error="errors.vinCode" v-model="item.vinCode"/>
        <Input name="Fuel Card #" placeholder="Fuel Card #" :error="errors.fuelCardNumber" v-model="item.fuelCardNumber"/>
        <Input name="Fuel Type" placeholder="Fuel Type" :error="errors.fuelType" v-model="item.fuelType"/>


    </div>
</template>

<script>
    export default {
        props: ['item', 'errors'],
        data() {
            return {
                
            }
        },
        methods: {
          
        },
        mounted() {
            
        }
    }
</script>

<style lang="scss" scoped>
.newItemContent {
    display: grid;
    row-gap: 10px;
}
</style>