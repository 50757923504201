<template>
    <div class="newItemContent">


        
        <div v-if="documents.length > 0" class="documents-list">
            <Document :key="item._id" v-for="(item, key) of documents" :item="item" @rename="renameDocument" @remove="removeDocument(item._id, key)"/>
        </div>

        <div v-else>
            <p>No documents</p>
        </div>
        
        <div class="upload-docs">
            <div class="uploading-section" v-if="uploadingDocs.length > 0" :key="uploadingUpdateTimestamp">
                <h3>Uploading</h3>
                <div>
                    <div class="uploading-doc" :key="key" v-for="(item, key) of uploadingDocs">
                        <div class="doc-header">
                            <div class="name">{{ item.originalname }}</div>
                            <div class="status">
                                <span v-if="!item.error">{{ item.percent }}%</span>
                                <span class="error" v-else>Error</span>
                            </div>
                        </div>
                        <div class="bar"><div class="percent" :style="[`width: ${item.percent}%`]"></div></div>
                    </div>
                </div>
            </div>
            <div class="upload-button">
                <p>Select and upload documents</p>
                <button class="default medium" @click="selectDocuments">Upload documents</button>
            </div>
        </div>

    </div>
</template>

<script>
import FilePicker from '../../mixins/FilePicker'
import { v4 as uuidv4 } from 'uuid'
import Document from './Document.vue'

    export default {
        mixins: [FilePicker],
        components: {
            Document
        },
        props: ['item', 'errors', 'documentsOptions'],
        data() {
            return {
                documents: [],
                uploadingDocs: [],
                uploadingUpdateTimestamp: new Date()
            }
        },
        methods: {
            async removeDocument(id, key) {
                let confirmDelete = await confirm("Do you really wish to delete this document?")
                if(!confirmDelete) return;

                this.documents.splice(key, 1);
                this.ajax('removeDocument', {
                    url: this.documentsOptions.removeUrl,
                    method: 'DELETE',
                    data: {
                        _id: id
                    }
                }, (err, body) => {});
            },
            renameDocument(id, name) {
                this.ajax('renameDocument', {
                    url: this.documentsOptions.renameUrl,
                    method: 'PUT',
                    data: {
                        name: name,
                        _id: id
                    }
                }, (err, body) => {});
            },
            getDocuments() {
                this.ajax('getListOfDocuments', {
                    url: this.documentsOptions.getUrl,
                    method: 'GET'
                }, (err, body) => {
                    if(!err) this.documents = body;
                });
            },
            selectDocuments() {
                this.createFilePicker(null, true, (files) => {
                    for(const file of files) {
                        this.uploadDocument(file);
                    }
                });
            },
            removeUploadingDoc(id) {
                for(let i = 0; i < this.uploadingDocs.length; i++) {
                    if(this.uploadingDocs[i].docId === id) {
                        this.uploadingDocs.splice(i, 1);
                        break;
                    }
                }
            },
            async uploadDocument(file) {
                let doc = {
                    docId: uuidv4(),
                    originalname: file.name,
                    percent: 0,
                    done: false,
                    error: false
                };

                this.uploadingDocs.push(doc);

                var data = new FormData();
                data.append('document', file);

                await this.ajax('FileStorageUpload-' + doc.docId, {
                    url: this.documentsOptions.uploadUrl,
                    method: 'POST',
                    data: data,
                    onUploadProgress: (progressEvent) => {
                        let percent = Number(Number((progressEvent.loaded * 100) / progressEvent.total).toFixed(0));
                        doc.percent = percent;
                        this.uploadingUpdateTimestamp = new Date();
                    }
                }, (err, body) => {
                    if(err) {
                        doc.error = true;
                    } else {
                        doc.done = true;
                        this.documents.push(body);
                        this.removeUploadingDoc(doc.docId);
                    }
                    this.uploadingUpdateTimestamp = new Date();
                });

            }
        },
        mounted() {
            this.getDocuments();
        }
    }
</script>

<style lang="scss" scoped>
.newItemContent {
    display: grid;
    row-gap: 30px;
}

.documents-list {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 10px;
    align-items: start;
}

.uploading-section {
    display: grid;
    gap: 20px;
    margin-bottom: 20px;
    padding: 0 0 20px 0;
    border-bottom: 1px solid $borderColor;
}

.upload-docs {
    padding: 30px;
    border: 2px dashed $borderColor;
    border-radius: 10px;
    background: $bgSecondary;
}

.upload-button {
    display: grid;
    justify-content: center;
    align-items: center;
    justify-items: center;
    gap: 15px;
    p {
        font-size: 12px;
    }
}

.uploading-doc {
    display: grid;
    gap: 5px;
    .doc-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .name {
            font-size: 12px;
        }
        .status {
            .error {
                color: $error;
            }
        }
    }
    .bar {
        width: 100%;
        height: 9px;
        border-radius: 3px;
        overflow: hidden;
        border: 1px solid $borderColor;
        background: $bg;
        .percent {
            width: 34%;
            height: 100%;
            background: $buttonColor;
        }
    }
}
</style>