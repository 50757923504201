<template>
    <div class="document" @click="showMenu" :class="[ { 'active' : menu }]" v-click-outside="hideMenu">
        <div class="icon">
            <div v-if="type === 'image'"><i class="fa-regular fa-file-image"></i></div>
            <div v-else-if="type === 'pdf'"><i class="fa-regular fa-file-pdf"></i></div>
            <div v-else><i class="fa-regular fa-file"></i></div>
        </div>
        <div class="name textNoWrap">
            <div  v-if="!rename">{{ item.originalname }}</div>
            <div v-else><input type="text" v-model="renameInput" ref="document-input-name" v-click-outside="hideRename" @keyup.enter="hideRename"></div>
        </div>
        <div class="size">{{ size }}</div>

        <div class="menu" :style="[`top: ${menuPos.y}px; left: ${menuPos.x}px`]" v-show="menu && !rename" @click.stop>
            <a href="" @click.prevent="download" target="_blank">Download</a>
            <a href="" @click.prevent="renameDoc">Rename</a>
            <a href="" @click.prevent="$emit('remove', this.item._id); hideMenu()">Delete</a>
        </div>

    </div>
</template>

<script>
    export default {
        props: ['item'],
        data() {
            return {
                menu: false,
                rename: false,
                renameInput: '',
                menuPos: { x: 0, y: 0 }
            }
        },
        methods: {
            showMenu(ev) {
                this.menuPos.x = ev.layerX;
                this.menuPos.y = ev.layerY;
                this.menu = true;
            },
            hideMenu() {
                this.menu = false;
            },
            download() {
                window.open(this.item.location, '_blank');
                setTimeout(this.hideMenu, 100);
            },
            renameDoc() {
                this.rename = true;
                this.renameInput = this.item.originalname;
                setTimeout(this.hideMenu, 100);
                this.$nextTick(() => this.$refs['document-input-name'].focus())
            },
            hideRename() {
                this.rename = false;
                this.hideMenu();
                if(this.renameInput.length >= 1) this.item.originalname = this.renameInput;
            }
        },
        computed: {
            type() {
                let type = this.item.mimetype;
                switch (type) {
                    case 'image/png':
                    case 'image/jpeg':
                    case 'image/jpg':
                        return 'image';
                        break;
                    case 'application/pdf':
                        return 'pdf';
                        break;
                    default:
                        return 'unknown';
                        break;
                }
            },
            size() {
                let bytes = this.item.size;
                let decimals = 2;
                if (!+bytes) return '0 Bytes'

                const k = 1024
                const dm = decimals < 0 ? 0 : decimals
                const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

                const i = Math.floor(Math.log(bytes) / Math.log(k))

                return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
            }
        },
        watch: {
            "item.originalname"() {
                this.$emit('rename', this.item._id, this.item.originalname);
            }
        }
    }
</script>

<style lang="scss" scoped>
.document {
    padding: 10px;
    border: 1px solid $borderColor;
    border-radius: 10px;
    display: grid;
    gap: 5px;
    color: $text;
    position: relative;
    z-index: 0;
    &.active {
        z-index: 1;
    }
    &:hover {
        background: $buttonColor2;
        cursor: pointer;
    }
}

.menu {
    position: absolute;
    top: 0;
    left: 0;
    background: $bgSecondary;
    box-shadow: $boxShadow;
    border: 1px solid $borderColor;
    border-radius: 10px;
    display: grid;
    width: 100px;
    overflow: hidden;
    a {
        display: flex;
        padding: 0 10px;
        align-items: center;
        height: 34px;
        font-size: 12px;
        &:hover {
            background: $bg;
        }
    }
}

.icon {
    font-size: 30px;
    margin: 10px auto;
}

.name {
    font-size: 12px;
    text-align: center;
    color: $buttonColor;
    input {
        width: 100%;
        border: 1px solid $borderColor;
        border-radius: 5px;
        padding: 0 5px;
        text-align: center;
    }
}

.size {
    font-size: 11px;
    text-align: center;
}
</style>