<template>
    <div class="truck">
        <div class="name">{{ truck.name }}</div>


        <div>
            <div v-if="track">
                <div class="address" v-if="locationCity"><router-link :to="`/units/track/${truck._id}`">{{ locationCity.placeName }}, {{ locationCity.stateAbbreviation }}, {{ locationCity.zipCode }}</router-link></div>
            </div>
        </div>

        <div>
            <div v-if="track">
                <div class="fuelLevelGrid">
                    <div class="fuelLevelHolder">
                        <div class="fuelPercent" :style="`width: ${track.fuel.value}%;`"></div>
                    </div>
                    <div class="percentage">{{ track.fuel.value }}%</div>
                </div>
                
            </div>
        </div>

        <div>{{ truck.licensePlate }}</div>
        <div class="driver">{{ driver ? driver.name : '' }}</div>
        <div class="trailer">{{ truck.trailerDetails ? truck.trailerDetails.name : '' }}</div>
        <div class="trackId">
            
            <div v-if="tracks.isTracked">
                <select v-model="truck.vehicleId">
                    <option :key="key" :value="vehicle.id" v-for="(vehicle, key) of tracks.vehicles">{{ vehicle.name }}</option>
                </select>
            </div>

        </div>
        <div class="actionBtns">
            <button @click="$emit('edit')"><i class="fa-solid fa-pencil"></i></button>
            <button @click="$emit('remove')" class="remove"><i class="fa-solid fa-trash-can"></i></button>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import addressParser from 'parse-address'
import addresser from 'addresser'

    export default {
        props: ['truck', 'tracks'],
        watch: {
            "truck.vehicleId"() {
                this.$emit('vehicleId', this.truck._id, this.truck.vehicleId);
            }
        },
        computed: {
            driver() {
                if(this.truck.driverDetails && this.truck.driverDetails[0]) {
                    return this.truck.driverDetails[0];
                } 
                return null;
            },
            track() {
                if(!this.tracks) return null;
                if(!this.tracks.isTracked) return null;

                for(const vehicle of this.tracks.vehicles) {
                    if(vehicle.id === this.truck.vehicleId) return vehicle;
                }

                return null;
            },
            locationCity() {
                if(!this.track) return null;
                try {
                    let location = this.track.gps.address;
                    return addresser.parseAddress(location);
                } catch(e) {
                    return null;
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.truck {
    position: relative;
    background: $bgSecondary;
    padding: 20px;
    align-items: center;
    border-bottom: 1px solid $borderColor;
    display: grid;
    column-gap: 10px;
    grid-template-columns: minmax(150px, 1fr) minmax(200px, 1fr) 100px 150px minmax(200px, 1fr) minmax(200px, 1fr) minmax(100px, 1fr) 55px;

    &:hover {
        z-index: 1;
        box-shadow: $boxShadow;
    }
    .name {
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
    }
    .details {
        color: $subtle;
    }
}

.fuelLevelGrid {
    display: grid;
    grid-template-columns: 40px auto;
    align-items: center;
    column-gap: 10px;
    .percentage {
        font-size: 12px;
    }
}

.fuelLevelHolder {
    width: 100%;
    height: 10px;
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid $borderColor;
    .fuelPercent {
        height: 100%;
        background: $success;
        transition: ease 0.5s;
    }
}

.actionBtns {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 7px;
    button {
        border: 0;
        border-bottom: 3px solid $borderColor;
        width: 24px;
        height: 24px;
        border-radius: 5px;
        font-size: 10px;
        color: $text;
        background: $buttonColor2;
        &.remove {
            color: #C0392B;
        }
    }
}

</style>