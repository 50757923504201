<template>
    <div class="listGrid">
        
        <div class="title">
            <h3>My trucks ({{ trucks.length }})</h3>
            <button class="default small" @click="editItem({})">Add</button>
        </div>

        <div class="list" v-if="trucks.length > 0">
            <div class="item header">
                <div class="col">Truck #</div>
                <div class="col">Location</div>
                <div class="col">Fuel Level</div>
                <div class="col">License Plate</div>
                <div class="col">Driver</div>
                <div class="col">Trailer</div>
                <div class="col">Track</div>
                <div class="col"></div>
            </div>


            <TruckItem 
                class="item" 
                @remove="removeItem(truck._id)" 
                @edit="editItem(truck)" 
                :key="truck._id" 
                v-for="truck of trucks" 
                :truck="truck"
                :tracks="tracking"
                @vehicleId="updateVehicleId"
            />

        </div>
        <div class="noTrucksMessage" v-else>
            <div class="icon"><i class="fa-solid fa-truck-fast"></i></div>
            <div class="message">You haven't added any trucks yet.</div>
        </div>

        <ModalSimple v-model="item.modal" :title="item.body._id ? 'Edit truck' : 'Add Truck'" @confirm="handleSave" confirm="Save truck" @close="closeAddModal">
            <SaveTruck :item="item.body" v-if="item.modal" :errors="item.errors"/>
        </ModalSimple>

    </div>
</template>

<script>
import TruckItem from '../TruckItem.vue'
import SaveTruck from './Save.vue'


    export default {
        components: {
            TruckItem,
            SaveTruck
        },
        props: ['trucks'],
        data() {
            return {
                item: {
                    modal: false,
                    body: {},
                    errors: {}
                },
                tracking: { isTracked: false }
            }
        },
        mounted() {
            this.getTrackingDetails();
        },
        methods: {
            updateVehicleId(truckId, vehicleId) {
                // vehicle id of tracking service
                this.ajax('UpdateTrackingVehicleId', {
                    url: `/myTrucks/${truckId}`,
                    method: 'PUT',
                    data: {
                        vehicleId: vehicleId
                    }
                }, (err, body) => { });
            },
            getTrackingDetails() {
                this.ajax('GetTrackingDetails', {
                    url: `/myTracking`,
                    method: 'GET',
                }, (err, body) => {
                    if(!err) {
                        this.tracking = body;
                    }
                });
            },
            removeItem(id) {
                let confirmDelete = confirm("Do you really want to delete this truck?");
                if(!confirmDelete) return;

                this.ajax('deleteTruck', {
                    url: `/myTrucks/${id}`,
                    method: 'DELETE',
                }, (err, body) => {
                    if(err) {
                        alert(body.message || 'Something went wrong. Please, try again!');
                        return;
                    }
                    this.$emit('refresh');
                });
            },
            closeAddModal() {
                this.item.body = {};
                this.item.errors = {};
            },
            editItem(item) {
                this.item.body = JSON.parse(JSON.stringify(item));
                this.item.modal = true;
            },
            handleSave() {
                this.item.errors = {};
                this.ajax('saveItemDetails', {
                    url: '/myTrucks',
                    method: 'POST',
                    data: this.item.body
                }, (err, body) => {
                    if(err) {
                        if(body.errors) this.item.errors = body.errors;
                        else alert(body.message || 'Something went wrong. Please, try again!');
                        return;
                    }
                    this.item.modal = false;
                    this.item.body = {};
                    this.$emit('refresh');
                });
            },
        },
    }
</script>

<style lang="scss" scoped>
.listGrid {
    display: grid;
    row-gap: 20px;
}


.title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.list {
    overflow-x: auto;
    border-radius: $borderRadius;
    .item {
        min-width: 1250px;
    }
    .header {
        display: grid;
        grid-template-columns: minmax(150px, 1fr) minmax(200px, 1fr) 100px 150px minmax(200px, 1fr) minmax(200px, 1fr) minmax(100px, 1fr) 55px;
        background: $bgSecondary;
        padding: 20px;
        align-items: center;
        border-bottom: 1px solid $borderColor;
        column-gap: 10px;
        font-size: 12px;
        .col {
            font-weight: bold;
            text-transform: uppercase;
        }
    }
}

.noTrucksMessage {
    padding: 100px 30px;
    display: grid;
    row-gap: 30px;
    align-items: center;
    justify-items: center;
    background: $bgSecondary;
    border-radius: $borderRadius;
    .icon {
        font-size: 50px;
    }
    .message {
        font-size: 14px;
        opacity: 0.8;
    }
}

.newItemContent {
    display: grid;
    row-gap: 20px;
}
</style>