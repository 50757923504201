<template>
    <div class="newItemContent">

        <Input name="Truck number" placeholder="Truck number" :error="errors.name" v-model="item.name"/>

        <div class="input">
            <div class="_header">
                <label>Truck Driver</label>
                <span class="_error">{{ errors.driver }}</span>
            </div>
            <select v-model="item.driver">
                <option :value="null">Select driver</option>
                <option :key="driver._id" :value="driver._id" v-for="driver of drivers">{{ driver.name }} {{ driver.truck[0] ? `[Currently: ${driver.truck[0].name}]` : '' }}</option>
            </select>
        </div>

        <div class="input">
            <div class="_header">
                <label>Trailer</label>
                <span class="_error">{{ errors.trailer }}</span>
            </div>
            <select v-model="item.trailer">
                <option :value="null">Select trailer</option>
                <option :key="trailer._id" :value="trailer._id" v-for="trailer of trailers">Trailer {{ trailer.name }}</option>
            </select>
        </div>

    </div>
</template>

<script>
    export default {
        props: ['item', 'errors'],
        data() {
            return {
                drivers: [],
                trailers: []
            }
        },
        methods: {
            getDrivers() {
                this.ajax('getListOfDrivers', {
                    url: '/myDrivers',
                    method: 'GET'
                }, (err, body) => {
                    if(!err) this.drivers = body;
                });
            },
            getTrailers() {
                this.ajax('getListOfTrailers', {
                    url: '/myTrailers',
                    method: 'GET'
                }, (err, body) => {
                    if(!err) this.trailers = body;
                });
            },
        },
        mounted() {
            this.getDrivers();
            this.getTrailers();
            if(!this.item.driver) { this.item.driver = null; }
            if(!this.item.trailer) { this.item.trailer = null; }
        }
    }
</script>

<style lang="scss" scoped>
.newItemContent {
    display: grid;
    row-gap: 10px;
}
</style>