<template>
    <div class="newItemContent">

        <Input name="Trailer #" placeholder="Trailer #" :error="errors.name" v-model="item.name"/>

    </div>
</template>

<script>
    export default {
        props: ['item', 'errors'],
        data() {
            return {
                
            }
        },
        methods: {
            
        },
        mounted() {
            
        }
    }
</script>

<style lang="scss" scoped>
.newItemContent {
    display: grid;
    row-gap: 10px;
}
</style>