<template>
    <div>
        <div class="trucksContent">
            <div class="trucksList">

                <div class="title">
                    <h2>My Units</h2>
                </div>

                <div class="headerHolder">
                    <div class="tabs">
                        <div class="tab" :class="{ 'active' : selectedTab == key }" @click="selectedTab = key" :key="key" v-for="(tab, key) of tabs">
                            <div class="name">{{ tab.name }}</div>
                            <span class="count">{{ tab.count }}</span>
                        </div>
                    </div>
                </div>

                <div class="list">
                    <component :is="tabs[selectedTab].component" :trucks="trucks" :trailers="trailers" @refresh="refresh"/>
                </div>

                
            </div>
        </div>

        

    </div>
</template>

<script>
import TrucksList from '../components/Trucks/List.vue'
import TrailersList from '../components/Trailers/List.vue'

    export default {
        components: {
            TrucksList,
            TrailersList
        },
        data() {
            return {
                trucks: [],
                trailers: [],
                selectedTab: 0
            }
        },
        computed: {
            tabs() {
                let array = [];

                array.push({
                    name: 'Trucks',
                    count: this.trucks.length,
                    component: TrucksList
                });

                array.push({
                    name: 'Trailers',
                    count: this.trailers.length,
                    component: TrailersList
                });

                return array;
            }
        },
        methods: {
            refresh() {
                this.getTrucks();
                this.getTrailers();
            },
            getTrucks() {
                this.ajax('getListOfTrucks', {
                    url: '/myTrucks',
                    method: 'GET'
                }, (err, body) => {
                    if(!err) this.trucks = body;
                });
            },
            getTrailers() {
                this.ajax('getListOfTrailers', {
                    url: '/myTrailers',
                    method: 'GET'
                }, (err, body) => {
                    if(!err) this.trailers = body;
                });
            },
        },
        mounted() {
            this.refresh();
        }
    }
</script>

<style lang="scss" scoped>

.headerHolder {
    border-bottom: 1px solid $borderColor;
    .tabs {
        display: flex;
        .tab {
            padding: 0 20px;
            display: grid;
            grid-template-columns: auto auto;
            height: 50px;
            align-items: center;
            column-gap: 15px;
            cursor: pointer;
            // border-radius: 5px 5px 0 0;
            transition: ease 0.3s;
            user-select: none;
            .count {
                padding: 5px 7px;
                background: $buttonColor2;
                border-radius: 5px;
                font-size: 12px;
                font-weight: bold;
                transition: ease 0.3s;
            }
            .name {
                font-size: 14px;
                transition: ease 0.3s;
                font-weight: bold;
            }
            &.active {
                background: $buttonColor2;
                color: $buttonColor;
                .count {
                    color: $buttonText;
                    background: $buttonColor;
                }
            }
        }
    }
}

.trucksContent {
    padding: 30px 0;
}

.trucksList {
    display: grid;
    row-gap: 20px;
}


.title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

</style>